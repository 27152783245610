<template>
  <div>
    <div style="position: relative;width: 100%;height: 100%">
<!--      第一-->
      <div :style="class1" @dblclick="test('1')">
        <iframe src="https://apps.r93535.com/sdxxh/frame.jsp" style="height: 100%;width: 100%;"></iframe>
      </div>
      <div :style="class2" @dblclick="test('2')">
        <img src="../../assets/bqjy.png" style="width: 100%;height: 100%">
      </div>
      <div :style="class3" @dblclick="test('3')">
        <iframe src="https://apps.r93535.com/wylc/show/index.action?sysmenu=83" style="height: 100%;width: 100%;"></iframe>
      </div>
      <div :style="class4" @dblclick="test('4')">
        <iframe src="http://cloud.shen-zhong.cn:81/chuanzangrailway/index.html" style="height: 100%;width: 100%;"></iframe>
      </div>
      <div :style="class5" @dblclick="test('5')">
        <iframe src="https://apps.r93535.com/cqdzyb/index/index" style="height: 100%;width: 100%;"></iframe>
      </div>
      <div :style="class6" @dblclick="test('6')">
        <img src="../../assets/fxy.png" style="width: 100%;height: 100%">
      </div>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "testiframe",
  data(){
    return{
      class6: {
        position: 'absolute',
        left: '70' + '%',
        top: '670' + 'px',
        width: '29.9' + '%',
        height: '400' + 'px'
      },
      class5: {
        position: 'absolute',
        left: '20%',
        top: '670px',
        width: '50%',
        height: '400px'
      },
      class4: {
        position: 'absolute',
        left: '70%',
        top: '335px',
        width: '29.8%',
        height: '330px'
      },
      class3: {
        position: 'absolute',
        left: '70%',
        width: '29.8%',
        height: '330px'
      },
      class2: {
        position: 'absolute',
        top: '670px',
        width: '20%',
        height: '400px'
      },
      class1: {
        position: 'absolute',
        width: '70%',
        height: '665px'
      }
    }
  },
  created() {
    if(window !==top){
      console.log('sdsa')
      top.location.href=location.href;
    }
  },
  methods:{
    test(val){
      console.log(val)
      if (val === '1'){
        ElMessage.error('请不要点击原始第一块内容');
      }
      let arr= {};
      let classtem = 'class' + val;
      if ('class2' === classtem){
        arr = this.class2;
        this.class2 = this.class1;
        this.class1 = arr;
      }
      if ('class3' === classtem){
        arr = this.class3;
        this.class3 = this.class1;
        this.class1 = arr;
      }
      if ('class4' === classtem){
        arr = this.class4;
        this.class4 = this.class1;
        this.class1 = arr;
      }
      if ('class5' === classtem){
        arr = this.class5;
        this.class5 = this.class1;
        this.class1 = arr;
      }
      if ('class6' === classtem){
        arr = this.class6;
        this.class6 = this.class1;
        this.class1 = arr;
      }
    },
    test1(val){
      console.log(val)
    }
  }
}
</script>

<style scoped>

</style>